import "./App.css";
import { useEffect, useState } from "react";
import { ChoiceListbox } from "./Components/Listbox";
import { DeiceReportModal } from "./Components/Modal";
import Card from "./Components/Card";
import { TopNavBarReporterPage } from "./Components/TopNavBar";
import Loading from "./Components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const choices1 = {
  answers: ["Please select an answer", "Yes", "No"],
  classes: ["w-2 h-2 bg-gray-600 rounded-full", "w-2 h-2 bg-green-600 rounded-full", "w-2 h-2 bg-red-600 rounded-full"],
};
const choices2 = {
  answers: ["Please select an answer", "1/4", "1/2", "3/4", "Full"],
  classes: [
    "w-2 h-2 bg-gray-600 rounded-full",
    "w-2 h-2 bg-red-600 rounded-full",
    "w-2 h-2 bg-green-600 rounded-full",
    "w-2 h-2 bg-green-600 rounded-full",
    "w-2 h-2 bg-green-600 rounded-full",
  ],
};

const today = new Date();
export const WEEKLY_CHECK_DAYNUMBER = 3;
export const STATIONS = ["SU2", "YNC", "ZEM", "YKQ", "YAT", "ZKE", "YFA", "YMO"];

function App() {
  //TODO change the back end so the fuel status can be uploaded to the database.
  const { state } = useLocation();
  const [skyjackIsOperational, setSkyjackIsOperational] = useState(undefined);
  const [skyjackIsAccessible, setSkyjackIsAccessible] = useState(undefined);
  const [skyjackTankLevel, setSkyjackTankLevel] = useState(undefined);
  const [deIceTankIsHot, setDeIceTankIsHot] = useState(undefined);
  const [deIceTankLevel, setDeIceTankLevel] = useState(undefined);
  const [deIcePumpIsChecked, setdeIcePumpIsChecked] = useState(undefined);
  const [fuelLevel, setFuelLevel] = useState(0);
  const [status, setStatus] = useState(undefined);
  const [comments, setComments] = useState("");
  const [reportedBy, setReportedBy] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [station, setStation] = useState(undefined);

  const navigate = useNavigate();

  const skyjackIsOperationalHandler = (v) => {
    setSkyjackIsOperational(v);
  };

  const skyjackIsAccessibleHandler = (v) => {
    setSkyjackIsAccessible(v);
  };

  const skyjackTankLevelHandler = (v) => {
    setSkyjackTankLevel(v);
  };

  const deIceTankIsHotHandler = (v) => {
    setDeIceTankIsHot(v);
  };

  const deIceTankLevelHandler = (v) => {
    setDeIceTankLevel(v);
  };

  const deIcePumpIsCheckedHandler = (v) => {
    setdeIcePumpIsChecked(v);
  };

  const commentsHandler = (e) => {
    setComments(e.target.value);
  };

  const reportedByHandler = (e) => {
    setReportedBy(e.target.value);
  };

  const fuelLevelHandler = (e) => {
    if (e.target.value <= 22000) {
      setFuelLevel(e.target.value);
    }
  };

  useEffect(() => {
    //IIFE (Immediately Invoked Function Expression)
    (async () => {
      try {
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          {
            withCredentials: true,
            headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
          }
        );
        console.log("App.js data from axios request :", data);
        if (data.isAuthenticated & data.reporter) {
          setIsLoggedIn(true);
          setStation(data.station);
        } else if (data.isAuthenticated) {
          navigate("/status");
        } else {
          setIsLoggedIn(false);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setIsLoggedIn(null);
      }
    })();
  }, [navigate]);

  useEffect(() => {
    if (skyjackIsOperational === choices1.answers[1] && skyjackIsAccessible === choices1.answers[1] && deIceTankIsHot === choices1.answers[1]) {
      if (station === "YAT") {
        if (fuelLevel > 7000) {
          setStatus(true);
        } else {
          setStatus(false);
        }
      } else {
        setStatus(true);
      }
    } else {
      setStatus(false);
    }

    //Weekly checks
    if (today.getDay() === WEEKLY_CHECK_DAYNUMBER) {
      if (
        deIcePumpIsChecked === choices1.answers[1] &&
        (deIceTankLevel === choices2.answers[2] || deIceTankLevel === choices2.answers[3] || deIceTankLevel === choices2.answers[4]) &&
        (skyjackTankLevel === choices2.answers[2] || skyjackTankLevel === choices2.answers[3] || skyjackTankLevel === choices2.answers[4])
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [skyjackIsOperational, skyjackIsAccessible, skyjackTankLevel, deIceTankIsHot, deIceTankLevel, deIcePumpIsChecked, fuelLevel, status]);

  useEffect(() => {
    console.log("Data passed from previous page :", state);
    console.log("Station name : ", station);
  }, [state, station]);

  return isLoggedIn === null ? (
    <Loading />
  ) : (
    <div className="grid h-screen md:place-items-center bg-gray-100 justify-center pt-20">
      <TopNavBarReporterPage />
      <div className="container mx-auto pt-10 md:pt-0 bg-gray-100">
        <Card
          cardTitle={`${station} deice status`}
          status={status}
          children={
            <>
              <div className="flex justify-between items-center pb-3 pl-3 pr-3 border-b">
                <div>Skyjack is operational :</div>
                <ChoiceListbox choices={choices1} callback={skyjackIsOperationalHandler}></ChoiceListbox>
              </div>

              <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b">
                <div>Skyjack is accessible :</div>
                <ChoiceListbox choices={choices1} callback={skyjackIsAccessibleHandler}></ChoiceListbox>
              </div>

              {today.getDay() === WEEKLY_CHECK_DAYNUMBER ? (
                <div className="flex justify-between items-center p-3 pl-3 pr-3 border-b">
                  <div>Skyjack gasoline tank level :</div>
                  <ChoiceListbox choices={choices2} callback={skyjackTankLevelHandler}></ChoiceListbox>
                </div>
              ) : null}

              {today.getDay() === WEEKLY_CHECK_DAYNUMBER ? (
                <div className="flex justify-between items-center p-3 border-b">
                  <div>De-ice tank level :</div>
                  <ChoiceListbox choices={choices2} callback={deIceTankLevelHandler}></ChoiceListbox>
                </div>
              ) : null}

              <div className="flex justify-between items-center p-3 border-b">
                <div>De-ice tank is hot :</div>
                <ChoiceListbox choices={choices1} callback={deIceTankIsHotHandler}></ChoiceListbox>
              </div>

              {today.getDay() === WEEKLY_CHECK_DAYNUMBER ? (
                <div className="flex justify-between items-center p-3 border-b">
                  <div>De-ice pump checked :</div>
                  <ChoiceListbox choices={choices1} callback={deIcePumpIsCheckedHandler}></ChoiceListbox>
                </div>
              ) : null}

              {station === "YAT" ? (
                <div className="flex justify-between items-center p-3 border-b">
                  <div>Jet Fuel quantity :</div>
                  <input type="number" min={0} max={22000} onChange={fuelLevelHandler} value={fuelLevel} className="border rounded-md w-72 p-2" />
                </div>
              ) : null}

              <div className="flex justify-between items-center p-3 border-b">
                <div>Comments :</div>
                <textarea className="border rounded-lg w-72 p-1 min-h-[60px] shadow-sm" onChange={commentsHandler}></textarea>
              </div>

              <div className="flex justify-between items-center p-3">
                <div>Reported by :</div>
                <input className="border rounded-lg w-72 p-1 shadow-sm" onChange={reportedByHandler}></input>
              </div>

              <div className="flex justify-between items-center mt-2">
                <button
                  className="bg-blue-600 rounded-lg text-white p-2 hover:bg-blue-500 w-full"
                  onClick={async () => {
                    //TODO refractor in a function for clarity
                    if (reportedBy?.length === 0 || reportedBy === undefined) {
                      alert("You must enter your name");
                      return;
                    } else {
                      try {
                        const response = await axios.post(
                          process.env.NODE_ENV === "development" ? "http://localhost:3000/post-status" : `${process.env.REACT_APP_PROD_URL}/post-status`,
                          {
                            station,
                            status: status,
                            skyjackOperational: skyjackIsOperational,
                            skyjackAccessible: skyjackIsAccessible,
                            deiceTankHot: deIceTankIsHot,
                            deicePumpChecked: deIcePumpIsChecked,
                            deiceTankLevel: deIceTankLevel,
                            skyjackTankLevel: skyjackTankLevel,
                            comments: comments,
                            reportedBy: reportedBy,
                          },
                          { withCredentials: true }
                        );
                        if (response.data.success) {
                          console.log("submitted with success");
                        } else {
                          console.log("Error");
                          alert(response.data.error.message);
                          return;
                        }
                        console.log(response.data);
                      } catch (error) {
                        console.log(error);
                        alert(error);
                        return;
                      }
                    }
                    setModalIsOpen((v) => {
                      return !v;
                    });
                  }}
                >
                  Submit report
                </button>
              </div>

              <DeiceReportModal
                status={status}
                station={station}
                reporter={reportedBy}
                reportedBy={reportedBy}
                skyjackOperational={skyjackIsOperational}
                skyjackAccessible={skyjackIsAccessible}
                deiceTankHot={deIceTankIsHot}
                deicePumpChecked={deIcePumpIsChecked}
                deiceTankLevel={deIceTankLevel}
                skyjackTankLevel={skyjackTankLevel}
                comments={comments}
                isOpen={modalIsOpen}
                closeCallback={() =>
                  setModalIsOpen((v) => {
                    return !v;
                  })
                }
              />
            </>
          }
        ></Card>
        {/* <CreatedBy /> */}
      </div>
    </div>
  );
}

export default App;
