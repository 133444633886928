export const signature = () => {
  let str = `____                         _        
    | __ )  ___  _ __  _ __   ___| |_ ___  
    |  _ \\ / _ \\| '_ \\| '_ \\ / _ \\ __/ _ \\ 
    | |_) | (_) | | | | | | |  __/ || (_) |
    |____/ \\___/|_| |_|_| |_|\\___|\\__\\___/     
    `;
  return str;
};

/**
 * Best way to sign your code...
 * @param {Function} str - a function that returns a string
 */
export const cowSay = (str) => {
  console.log(`
    ______________________________________
     ${str()}
    Whenever I'm about to do something, I think, 
    "Would an idiot do that?" And if they would, 
    I do not do that thing.
    ---------------------------------------
            \\   ^__^
             \\  (oo)\\_______
                (__)\\       )\\/\\
                    ||----w |
                    ||     ||
    `);
};
