/**
 * A functino that tells you if the dataToCheck is today
 * @param {Date} dateToCheck
 */

export const isToday = (dateToCheck) => {
  const today = new Date();
  if (today.getDate() === dateToCheck.getDate() && today.getMonth() === dateToCheck.getMonth() && today.getFullYear() === dateToCheck.getFullYear()) {
    return true;
  } else {
    return false;
  }
};