import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "./Components/Loading";
import Card from "./Components/Card";
import { TopNavBarReporterPage, TopNavBarStatusPage } from "./Components/TopNavBar";
import { add, eachDayOfInterval } from "date-fns";
//*Testing charts
import ChartLine from "./Components/ChartLine";

const STATIONS = ["SU2", "YNC", "ZEM", "YKQ", "YAT", "ZKE", "YFA", "YMO"];

const Admin = () => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [daysOfInterval, setDaysOfInterval] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [chartData, setChartData] = useState(undefined);

  //*TESTING
  const [stationStatusesHistory, setStationStatusesHistory] = useState([]);
  const [stationToCheckForDate, setStationToCheckForDate] = useState(undefined);
  const [chart2Data, setChart2Data] = useState(undefined);
  const [dateForStation, setDateForStation] = useState(undefined);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        max: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        callbacks: {
          footer: (v) => {
            let index = v[0].dataIndex;
            let stringArr = [];
            for (let el of statuses[index]) {
              let str = el.station + ": de-ice status is " + el.status;
              stringArr.push(str);
            }
            //TODO Find missing stations and indicate not reported
            let missingStations = [];
            for (let refStation of STATIONS) {
              let refStationMissing = true;
              for (let el of statuses[index]) {
                if (el.station === refStation) {
                  refStationMissing = false;
                }
              }
              if (refStationMissing) {
                missingStations.push(refStation);
              }
            }
            for (let el of missingStations) {
              stringArr.push(`${el} de-ice status is not reported`);
            }
            stringArr.push(` `);
            stringArr.push(`Total stations reported : ${statuses[index].length}`);
            return stringArr;
          },
        },
      },
    },
  };

  const chart2Options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "category",
        labels: ["true", "false"],
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        callbacks: {
          footer: (v) => {
            let index = v[0].dataIndex;
            let stringArr = [];
            for (let el of statuses[index]) {
              let str = el.station + ": de-ice status is " + el.status;
              stringArr.push(str);
            }
            //TODO Find missing stations and indicate not reported
            let missingStations = [];
            for (let refStation of STATIONS) {
              let refStationMissing = true;
              for (let el of statuses[index]) {
                if (el.station === refStation) {
                  refStationMissing = false;
                }
              }
              if (refStationMissing) {
                missingStations.push(refStation);
              }
            }
            for (let el of missingStations) {
              stringArr.push(`${el} de-ice status is not reported`);
            }
            stringArr.push(` `);
            stringArr.push(`Total stations reported : ${statuses[index].length}`);
            return stringArr;
          },
        },
      },
    },
  };

  useEffect(() => {
    //IIFE (Immediately Invoked Function Expression)
    (async () => {
      try {
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          {
            withCredentials: true,
            headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
          }
        );
        console.log("App.js data from axios request :", data);
        if (data.isAuthenticated & data.admin) {
          setIsLoggedIn(true);
        } else if (data.isAuthenticated) {
          navigate("/status");
        } else {
          setIsLoggedIn(false);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setIsLoggedIn(null);
      }
    })();
  }, [navigate]);

  useEffect(() => {
    //getStationsHistoryForDate(startDate, endDate);
    if (startDate !== undefined && endDate !== undefined) {
      //!Will crash if dates are not selected in chronological order...
      try {
        setDaysOfInterval(eachDayOfInterval({ start: startDate, end: endDate }));
      } catch (error) {
        console.log(error);
        alert("Select dates that are in chronological order please.");
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const getStationsHistoryForDateRange = async (dateRange) => {
      if (dateRange.length > 0) {
        setStatuses([]);
        for (let day of dateRange) {
          await getStationsHistoryForDate(day);
          await getStationHistoryForDate(day, "SU2", 1);
          await getStationHistoryForDate(day, "YNC", 1);
        }
      }
    };
    getStationsHistoryForDateRange(daysOfInterval);
  }, [daysOfInterval]);

  useEffect(() => {
    if (daysOfInterval.length > 0) {
      setChartData({
        labels: daysOfInterval.map((v) => v.toLocaleDateString()),
        datasets: [
          {
            label: "Number of station with a positive de-ice status",
            data: statuses.map((stations) => {
              let count = 0;
              //console.log(stations);
              for (let station of stations) {
                if (station.status === true) {
                  count++;
                }
              }
              return count;
            }),
            borderColor: "rgb(0, 128, 0)",
            backgroundColor: "rgba(0, 128, 0, 0.5)",
          },
          {
            data: new Array(statuses.length).fill(STATIONS.length),
            label: "Total number of stations",
            borderColor: "rgb(0, 0, 255)",
            backgroundColor: "rgba(0, 0, 255, 0.5)",
          },
        ],
      });

      setChart2Data({
        labels: daysOfInterval.map((v) => v.toLocaleDateString()),
        datasets: [
          {
            label: "Number of station with a positive de-ice status",
            data: stationStatusesHistory.map((obj) => {
              if (obj.length === 1) {
                if (obj[0].status === true) {
                  return "true";
                } else {
                  return "false";
                }
              } else {
                return "false";
              }
            }),
            borderColor: "rgb(0, 128, 0)",
            backgroundColor: "rgba(0, 128, 0, 0.5)",
          },
        ],
      });
    }
  }, [statuses, stationStatusesHistory, daysOfInterval]);

  //*Debug only
  useEffect(() => {
    console.log("Status for all de-ice stations: ", statuses);
    console.log("Station statuses history :", stationStatusesHistory);
  }, [statuses, stationStatusesHistory]);

  const getStationsHistoryForDate = async (date) => {
    const { data } = await axios.post(
      process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status-for-date" : `${process.env.REACT_APP_PROD_URL}/get-all-status-for-date`,
      { stations: STATIONS, date },
      { withCredentials: true }
    );
    setStatuses((v) => [...v, data]);
  };

  const getStationHistoryForDate = async (date, station, limit) => {
    const { data } = await axios.post(
      process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status-for-date" : `${process.env.REACT_APP_PROD_URL}/get-all-status-for-date`,
      { stations: [station], date, limit },
      { withCredentials: true }
    );
    console.log("getStationHistoryForDate", data);
    setStationStatusesHistory((v) => [...v, data]);
  };

  const startDateChangeHandler = (event) => {
    let date = add(new Date(event.target.value), { minutes: new Date(event.target.value).getTimezoneOffset() });
    console.log("Start date :", date);
    console.log("Start date: ", date.toISOString());
    setStartDate(date);
  };
  const endDateChangeHandler = (event) => {
    let date = add(new Date(event.target.value), { minutes: new Date(event.target.value).getTimezoneOffset() });
    console.log("End date :", date);
    console.log("End date :", date.toISOString());
    setEndDate(date);
  };

  return isLoggedIn === null ? (
    <Loading />
  ) : (
    <div className="bg-gray-100">
      <TopNavBarStatusPage fixed={true} />
      {/* <div className="grid h-screen md:place-items-center bg-gray-100 justify-center pt-20"> */}
      <div className="container mx-auto bg-gray-100 p-2 ">
        <div className="grid grid-cols-2 h-screen justify-items-center bg-gray-100  pt-20">
          {/* <div className="container mx-auto bg-gray-100 p-2"> */}
          <Card
            status={null}
            cardTitle={`De-ice statuses from ${startDate === undefined ? "-" : startDate?.toLocaleDateString()} to ${
              endDate === undefined ? "-" : endDate?.toLocaleDateString()
            }`}
            children={
              <div>
                <div className="flex flex-row items-center">
                  <div>From</div>
                  <input type="date" onChange={startDateChangeHandler} className="border p-1 mx-1 rounded-md" />
                  <div>To</div>
                  <input type="date" onChange={endDateChangeHandler} className="border p-1 mx-1 rounded-md" />
                </div>
                <div className="mt-2 h-[300px]">{chartData === undefined ? null : <ChartLine data={chartData} options={chartOptions} />}</div>
              </div>
            }
          />

          <Card
            status={null}
            cardTitle={`NOT READY FOR PRODUCTION`}
            children={
              <div>
                {/* <div className="flex flex-row items-center">
                  <div>From</div>
                  <input type="date" onChange={startDateChangeHandler} className="border p-1 mx-1 rounded-md" />
                  <div>To</div>
                  <input type="date" onChange={endDateChangeHandler} className="border p-1 mx-1 rounded-md" />
                </div> */}
                <div className="mt-2 h-[335px]">{chartData === undefined ? null : <ChartLine data={chart2Data} options={chart2Options} />}</div>
              </div>
            }
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Admin;
