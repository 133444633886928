import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { WEEKLY_CHECK_DAYNUMBER } from "../App";
import { isToday } from "../utils/Date";

const TODAY = new Date();

export function DeiceReportModal({
  isOpen,
  closeCallback,
  status,
  reporter,
  station,
  skyjackOperational,
  skyjackAccessible,
  deiceTankHot,
  deicePumpChecked,
  deiceTankLevel,
  skyjackTankLevel,
  comments,
  reportedBy,
}) {
  const mailOpener = (sendTo) => {
    let email;
    if (sendTo === "plourde") {
      email = "plourded@aircreebec.ca";
    }
    if (sendTo === "clayton") {
      email = "richardc@aircreebec.ca";
    }
    window.open(
      TODAY.getDay() === WEEKLY_CHECK_DAYNUMBER
        ? `mailto:${email}?subject=De-ice not operational in ${station}&body=${station} de-ice status: %0D%0A%0D%0A 
              - Skyjack is operational: ${skyjackOperational}%0D%0A 
              - Skyjack is accessible: ${skyjackAccessible}%0D%0A 
              - Deice tank is hot: ${deiceTankHot}%0D%0A 
              - Deice pump is checked: ${deicePumpChecked}%0D%0A 
              - Deice tank level: ${deiceTankLevel}%0D%0A 
              - Skyjack tank level: ${skyjackTankLevel}%0D%0A 
              - Comments: ${comments}%0D%0A
              - Reported by: ${reportedBy}%0D%0A%0D%0A
              Thank you!`
        : `mailto:${email}?subject=De-ice not operational in ${station}&body=${station} de-ice status: %0D%0A%0D%0A 
              - Skyjack is operational: ${skyjackOperational}%0D%0A 
              - Skyjack is accessible: ${skyjackAccessible}%0D%0A 
              - Deice tank is hot: ${deiceTankHot}%0D%0A 
              - Comments: ${comments}%0D%0A
              - Reported by: ${reportedBy}%0D%0A%0D%0A
              Thank you!`
    );
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeCallback}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6  align-middle shadow-xl transition-all relative">
                  <div className="p-0 m-0 top-2 right-2 absolute">
                    <button className="hover:bg-gray-100" onClick={closeCallback}>
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4"
                        fill="gray"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>

                  <Dialog.Title
                    as="h3"
                    className={
                      status === undefined
                        ? "text-2xl font-bold leading-6 text-white bg-red-500 p-3 rounded-lg mb-5"
                        : status
                        ? "text-2xl font-bold leading-6 text-white bg-green-500 p-3 rounded-lg mb-5"
                        : "text-2xl font-bold leading-6 text-white bg-red-500 p-3 rounded-lg mb-5"
                    }
                  >
                    {status === undefined ? (
                      <p>
                        <span className="animate-pulse">⚠️</span> Deice is not operational
                        <span className="pl-1 animate-pulse">⚠️</span>
                      </p>
                    ) : status ? (
                      "✓ Deice is operational ✓"
                    ) : (
                      <p>
                        <span className="animate-pulse">⚠️</span> Deice is not operational
                        <span className="pl-1 animate-pulse">⚠️</span>
                      </p>
                    )}
                  </Dialog.Title>
                  <div className="mt-2">
                    {status === undefined || status === false ? (
                      <div className="bg-gray-50 rounded-lg">
                        <p className="pt-2 mb-5 font-bold text-lg">{reporter}, you must contact :</p>
                        <ul>
                          <li className="font-bold">Dan Plourde :</li>
                          <li className="mb-3 pb-3 border-b" onClick={() => mailOpener("plourde")}>
                            📞 705-264-9521 #3115 | 📬 plourded@aircreebec.ca
                          </li>
                          <li className="font-bold">Richard Clayton :</li>
                          <li className="mb-3 pb-3 border-b" onClick={() => mailOpener("clayton")}>
                            📞 705-264-9521 #3116 | 📬 richardc@aircreebec.ca
                          </li>

                          {/* <li className="font-bold">Manager 3 :</li>
                          <li className="mb-3 pb-3" onClick={() => mailOpener()}>
                            📞 1-800-xxx-xxxx | 📬 manager3@email.ca
                          </li> */}
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <p className="font-bold">No further action required, thank you for completing the report !</p>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export function DeiceStatusModal({ isOpen, closeCallback, dataQC, dataON }) {
  const FIELDS = [
    "Station",
    "Skyjack Operational",
    "Skyjack accessible",
    "Tank is hot",
    "Deice pump checked",
    "Deice tank level",
    "Skyjack tank level",
    "Comments",
    "Reported by",
    "Reported at",
  ];

  /**
   * Function used to generate each row of each FIELDS
   * @param {*} data - Either dataQC or dataON, an array containing the status of each airport.
   * @param {*} fieldIndex - Index of FIELDS
   * @returns - A <div></div> element
   */
  const tableGeneartor = (data, fieldIndex) => {
    return data?.map((value, index) => {
      if (fieldIndex === 0) {
        //Station
        return (
          <div className="p-2 border border-t-0 border-x-0 font-bold" key={index}>
            <div
              className={
                value.status & isToday(new Date(value.createdAt))
                  ? "w-fit px-2 rounded-lg text-white bg-green-500"
                  : "w-fit px-2 rounded-lg text-white bg-red-500"
              }
            >
              {value.station}
            </div>
          </div>
        );
      }
      if (fieldIndex === 1) {
        //Skyjack is operational
        return (
          <div
            className={
              value.skyjackOperational === "No"
                ? "p-2 border border-t-0 border-x-0 text-red-500"
                : "p-2 border border-t-0 border-x-0"
            }
            key={index}
          >
            {value.skyjackOperational}
          </div>
        );
      }
      if (fieldIndex === 2) {
        //Skyjack is accessible
        return (
          <div
            className={
              value.skyjackAccessible === "No"
                ? "p-2 border border-t-0 border-x-0 text-red-500"
                : "p-2 border border-t-0 border-x-0"
            }
            key={index}
          >
            {value.skyjackAccessible}
          </div>
        );
      }
      if (fieldIndex === 3) {
        // tank is hot
        return (
          <div
            className={
              value.deiceTankHot === "No"
                ? "p-2 border border-t-0 border-x-0 text-red-500"
                : "p-2 border border-t-0 border-x-0"
            }
            key={index}
          >
            {value.deiceTankHot}
          </div>
        );
      }
      if (fieldIndex === 4) {
        //Deice pump checked
        return (
          <div
            className={
              value.deicePumpChecked === "No"
                ? "p-2 border border-t-0 border-x-0 text-red-500"
                : "p-2 border border-t-0 border-x-0"
            }
            key={index}
          >
            {value.deicePumpChecked === undefined ? "N/A" : value.deicePumpChecked}
          </div>
        );
      }
      if (fieldIndex === 5) {
        //Deice tank level
        return (
          <div className="p-2 border border-t-0 border-x-0" key={index}>
            {value.deiceTankLevel === undefined ? "N/A" : value.deiceTankLevel}
          </div>
        );
      }
      if (fieldIndex === 6) {
        //Skyjack tank level
        return (
          <div className="p-2 border border-t-0 border-x-0" key={index}>
            {value.skyjackTankLevel === undefined ? "N/A": value.skyjackTankLevel}
          </div>
        );
      }
      if (fieldIndex === 7) {
        //Comments
        return (
          <div className="p-2 border border-t-0 border-x-0" key={index}>
            {value.comments === "" || value.comments === undefined ? "No comments" : value.comments}
          </div>
        );
      }
      if (fieldIndex === 8) {
        //Reported by
        return (
          <div className="p-2 border border-t-0 border-x-0" key={index}>
            {value.reportedBy}
          </div>
        );
      }
      if (fieldIndex === 9) {
        //Reported at
        return (
          <div
            className={
              isToday(new Date(value.createdAt))
                ? "p-2 border border-t-0 border-x-0"
                : "p-2 border border-t-0 border-x-0 text-red-500"
            }
            key={index}
          >
            {value.createdAt}
          </div>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeCallback}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto w-2/6 h-[490px] rounded-lg bg-white p-5 shadow-xl transition-all relative">
                  <div className="p-0 m-0 absolute right-2 top-2">
                    <button className="hover:bg-gray-100" onClick={closeCallback}>
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4"
                        fill="gray"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>

                  <Dialog.Title as="h3" className={"text-2xl text-center text-gray-800 rounded-md p-2 font-semibold"}>
                    ONTARIO & QUEBEC
                  </Dialog.Title>
                  <div className="absolute inset-6 top-20 overflow-x-auto bg-gray-100 rounded-lg p-2">
                    <div className="flex flex-nowrap pb-2 mb-4 text-left">
                      {/* Where the table is generated mapping through each FIELDS and filling the result for each station for a specific FIELD */}
                      {FIELDS.map((value, fieldIndex) => {
                        if (TODAY.getDay() === WEEKLY_CHECK_DAYNUMBER) {
                          return (
                            <div className="min-w-max flex-row" key={fieldIndex}>
                              <div
                                className="font-bold border border-t-0 border-r-0 border-l-0 border-b-2 border-black p-2"
                                key={fieldIndex}
                              >
                                {value}
                              </div>
                              {tableGeneartor(dataQC, fieldIndex)}
                              <div className="border-2 border-t-0 border-x-0"></div>
                              {tableGeneartor(dataON, fieldIndex)}
                            </div>
                          );
                        } else {
                          if (fieldIndex === 4 || fieldIndex === 5 || fieldIndex === 6) {
                            return null;
                          } else {
                            return (
                              <div className="min-w-max flex-row" key={fieldIndex}>
                                <div
                                  className="font-bold border border-t-0 border-r-0 border-l-0 border-b-2 border-black p-2"
                                  key={fieldIndex}
                                >
                                  {value}
                                </div>
                                {tableGeneartor(dataQC, fieldIndex)}
                                <div className="border-2 border-t-0 border-x-0"></div>
                                {tableGeneartor(dataON, fieldIndex)}
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
