import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import { LoginCard } from "./Components/Card";
import { CreatedBy } from "./Components/CreatedBy";
import { TopNavBarStatusPage } from "./Components/TopNavBar";
import Loading from "./Components/Loading";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [station, setStation] = useState("");
  const [reporterEnabled, setReporterEnabled] = useState(false);
  const [adminEnabled, setAdminEnabled] = useState(false);
  const [superAdminEnabled, setSuperAdminEnabled] = useState(false);

  const [error, setError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axios.post(
      process.env.NODE_ENV === "development" ? "http://localhost:3000/register" : `${process.env.REACT_APP_PROD_URL}/register`,
      {
        username: username,
        password: password,
        reporter: reporterEnabled,
        admin: adminEnabled,
        superAdmin: superAdminEnabled,
        station: station,
      },
      { withCredentials: true }
    );
    if (data.success) {
      alert("All good");
      setusername("");
      setpassword("");
      setStation("");
      setReporterEnabled(false);
      setAdminEnabled(false);
      setSuperAdminEnabled(false);
    } else {
      setError(true);
      alert(data.error);
    }
  };

  useEffect(() => {
    //IIFE (Immediately Invoked Function Expression) to check if the user is logged in and has a session.
    (async () => {
      try {
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          { withCredentials: true }
        );
        console.log("Response.data : ", data);
        if (data.isAuthenticated && data.superAdmin) {
          setIsLoggedIn(true);
        } else if (data.isAuthenticated) {
          setIsLoggedIn(null);
          navigate("/status");
        } else {
          setIsLoggedIn(false);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setIsLoggedIn(false);
      }
    })();
  }, [navigate]);

  return isLoggedIn === null ? (
    <Loading />
  ) : (
    <div>
      <TopNavBarStatusPage fixed={true}/>
      <div className="grid h-screen md:place-items-center bg-gray-100 justify-center">
        <div className="container mx-auto pt-10 md:pt-0 bg-gray-100">
          <LoginCard
            cardTitle={"Register"}
            children={
              <form onSubmit={handleSubmit}>
                {/* USERNAME */}
                <div className="relative mb-4">
                  <input
                    id="username"
                    type="text"
                    name="Username"
                    className={
                      error
                        ? "block px-2.5 pb-2.5 pt-4 w-full text text-gray-900 bg-transparent rounded-lg border-2 border-red-500 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-500 peer"
                        : "block px-2.5 pb-2.5 pt-4 w-full text text-gray-900 bg-transparent rounded-lg border-2 border-gray-200 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    }
                    placeholder=" "
                    onChange={(e) => setusername(e.target.value)}
                  />
                  <label
                    htmlFor="username"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] bg-transparent dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 left-1"
                  >
                    {"Username"}
                  </label>
                </div>

                {/* PASSWORD */}
                <div className="relative mb-4">
                  <input
                    id="password"
                    type="password"
                    name="Password"
                    className={
                      error
                        ? "block px-2.5 pb-2.5 pt-4 w-full text text-gray-900 bg-transparent rounded-lg border-2 border-red-500 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-500 peer"
                        : "block px-2.5 pb-2.5 pt-4 w-full text text-gray-900 bg-transparent rounded-lg border-2 border-gray-200 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    }
                    placeholder=" "
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  <label
                    htmlFor="password"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] bg-transparent dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 left-1"
                  >
                    Password
                  </label>
                </div>

                {/* STATION */}
                <div className="relative mb-4">
                  <input
                    id="station"
                    type="text"
                    name="Station"
                    className={
                      "block px-2.5 pb-2.5 pt-4 w-full text text-gray-900 bg-transparent rounded-lg border-2 border-gray-200 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    }
                    placeholder=" "
                    onChange={(e) => setStation(e.target.value)}
                  />
                  <label
                    htmlFor="station"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] bg-transparent dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 left-1"
                  >
                    Station
                  </label>
                </div>

                {/* Reporter */}
                <div className="flex mb-4 justify-between">
                  <div>Reporter</div>
                  <Switch
                    checked={reporterEnabled}
                    onChange={setReporterEnabled}
                    className={`${reporterEnabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span
                      className={`${reporterEnabled ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>

                {/* Reporter */}
                <div className="flex mb-4 justify-between">
                  <div>Admin</div>
                  <Switch
                    checked={adminEnabled}
                    onChange={setAdminEnabled}
                    className={`${adminEnabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span className={`${adminEnabled ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                  </Switch>
                </div>

                {/* Reporter */}
                <div className="flex mb-4 justify-between">
                  <div>Super Admin</div>
                  <Switch
                    checked={superAdminEnabled}
                    onChange={setSuperAdminEnabled}
                    className={`${superAdminEnabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span
                      className={`${superAdminEnabled ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
                <div>
                  <input type="submit" value="Submit" className="bg-blue-600 rounded-lg text-white p-2 hover:bg-blue-500 w-full" />
                </div>
              </form>
            }
          ></LoginCard>
          <CreatedBy />
        </div>
      </div>
    </div>
  );
};

export default Register;
