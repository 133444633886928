import { useEffect, useState } from "react";
import { CreatedBy } from "./Components/CreatedBy";
import { MapComponent } from "./Components/Map";
import { DeiceStatusModal } from "./Components/Modal";
import { TopNavBarStatusPage } from "./Components/TopNavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isToday } from "./utils/Date";
import Loading from "./Components/Loading";

function StatusPage() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [dataQC, setDataQC] = useState(undefined);
  const [dataON, setDataON] = useState(undefined);

  useEffect(() => {
    // Immediately Invoked Function Expression to check if user is authenticated and to download data.
    (async () => {
      try {
        const { data } = await axios.get(
          process.env.NODE_ENV === "development" ? "http://localhost:3000/check-session" : `${process.env.REACT_APP_PROD_URL}/check-session`,
          {
            withCredentials: true,
            headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
          }
        );

        if (data.isAuthenticated) {
          setIsLoggedIn(true);
          //Downloading QC deice status
          const responseQC = await axios.post(
            process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status" : `${process.env.REACT_APP_PROD_URL}/get-all-status`,
            { stations: ["SU2", "YNC", "ZEM", "YKQ"] },
            { withCredentials: true }
          );
          setDataQC(responseQC.data);
          //Downloading ON deice status
          const responseON = await axios.post(
            process.env.NODE_ENV === "development" ? "http://localhost:3000/get-all-status" : `${process.env.REACT_APP_PROD_URL}/get-all-status`,
            { stations: ["YAT", "ZKE", "YFA", "YMO"] },
            { withCredentials: true }
          );
          setDataON(responseON.data);
          //*DEBUG
          for (let station of responseQC.data) {
            console.log(`Station ${station.station} has been updated today ? ${isToday(new Date(station.createdAt))}`);
          }
          for (let station of responseON.data) {
            console.log(`Station ${station.station} has been updated today ? ${isToday(new Date(station.createdAt))}`);
            console.log(station, station.createdAt)
            console.log(new Date(station.createdAt))
          }
        } else {
          setIsLoggedIn(false);
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        setIsLoggedIn(null);
      }
    })();
  }, [navigate]);

  return isLoggedIn === null ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-screen h-screen bg-gray-100 ">
      <div>
        <TopNavBarStatusPage
          modalCallback={() =>
            setModalIsOpen((v) => {
              return !v;
            })
          }
          isStatusPage={true}
        />
      </div>
      <div className="shadow w-full h-full">
        <MapComponent dataQC={dataQC} dataON={dataON} />
      </div>

      <DeiceStatusModal
        isOpen={modalIsOpen}
        closeCallback={() =>
          setModalIsOpen((v) => {
            return !v;
          })
        }
        dataQC={dataQC}
        dataON={dataON}
      />
      <CreatedBy />
    </div>
  );
}

export default StatusPage;
