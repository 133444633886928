import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./mapLegend.css";

export function Legend() {
  const map = useMap();
  useEffect(() => {
    if (map) {
      if (document.getElementById("Temp") === null) {
        const legend = L.control({ position: "bottomright" });

        legend.onAdd = () => {
          const div = L.DomUtil.create("div", "legend");
          div.innerHTML =
            "<h4>Temperature</h4>" +
            "<div class='gradiant'></div>" +
            "<div class='flex flex-item'>" +
            "<div class=''>-40</div>" +
            "<div class=''>+30</div>" +
            "</div>";
          div.id = "Temp";
          return div;
        };

        legend.addTo(map);
      }
    }
  }, [map]);
  return null;
}
