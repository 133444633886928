import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Popup, CircleMarker, WMSTileLayer, LayersControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import { Legend } from "./MapLegend";
import { isToday } from "../utils/Date";

import "leaflet/dist/leaflet.css";
import "./map.css";

const { BaseLayer } = LayersControl;
const { Overlay } = LayersControl;

export const MapComponent = ({ dataQC, dataON }) => {
  const [showTempLegend, setShowTempLegend] = useState(false);
  //const legendElement = useRef();
  const [forceRefresh, setForceRefresh] = useState("initialState");

  useEffect(() => {
    console.log("Quebec data : ", dataQC);
    console.log("Ontario data : ", dataON);
    if (dataON?.length > 0) {
      console.log("test")
      setForceRefresh("updatedState");
    }
  }, [dataON, dataQC]);

  const defaultPosition = [53.73627011852602, -80.5569678861667];
  //TODO fixed it to give more giggling room around Quebec and Ontario area.
  const maxBounds = [[58.56326836781133, -92.34665539356432],[38.405784343276714, -65.43612308178963]]
  const redOptions = { color: "red" };
  const greenOptions = { color: "green" };

  const createClusterCustomIcon = function (cluster) {
    let _class;
    if (forceRefresh === "initialState") {
      _class = "custom-marker-cluster-green";
    }
    if (forceRefresh === "updatedState") {
      _class = "custom-marker-cluster-green";
      for (let airport of dataON) {
        if (airport.station === "YFA") {
          //* Should be fixed now, red if false or yesterday.
          if (airport.status === false || isToday(new Date(airport.createdAt)) === false) {
            _class = "custom-marker-cluster-red";
          }
        }
        if (airport.station === "ZKE") {
          if (airport.status === false || isToday(new Date(airport.createdAt)) === false) {
            _class = "custom-marker-cluster-red";
          }
        }
      }
    }
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: _class,
      iconSize: L.point(33, 33, true),
    });
  };

  return (
    <MapContainer center={defaultPosition} zoom={6} maxZoom={12} minZoom={5} maxBounds={maxBounds} className="w-full h-full z-10">
      <LayersControl>
        <BaseLayer checked name="Open street map">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </BaseLayer>
        <Overlay checked={false} name="Temperature">
          <TileLayer
            url="https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=a5f80cdd4f9a555892e1d1479d707785"
            eventHandlers={{
              add: () => {
                setShowTempLegend(true);
              },
              remove: () => {
                document.getElementById("Temp").remove();
                setShowTempLegend(false);
              },
            }}
          />
          {showTempLegend ? (
            <div>
              <Legend></Legend>
            </div>
          ) : null}
        </Overlay>
        <Overlay name="Clouds">
          <TileLayer url="https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=a5f80cdd4f9a555892e1d1479d707785" />
        </Overlay>
        <Overlay name="Precipitation">
          <TileLayer url="https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=a5f80cdd4f9a555892e1d1479d707785" />
        </Overlay>
        <Overlay name="Radar">
          <WMSTileLayer
            layers="RADAR_1KM_RRAI"
            url="https://geo.weather.gc.ca/geomet?"
            transparent={true}
            format={"image/png"}
          ></WMSTileLayer>
        </Overlay>
      </LayersControl>

      {dataQC?.map((value, index) => {
        return (
          <CircleMarker
            center={value.stationCoordinates}
            pathOptions={value.status & isToday(new Date(value.createdAt)) ? greenOptions : redOptions}
            radius={10}
            key={index}
          >
            <Popup className="w-max" closeButton={false}>
              <MapStatusComponent stationObject={value} />
            </Popup>
          </CircleMarker>
        );
      })}

      <MarkerClusterGroup
        chunkedLoading
        maxClusterRadius={50}
        iconCreateFunction={createClusterCustomIcon}
        key={forceRefresh}
      >
        {dataON?.map((value, index) => {
          return (
            <CircleMarker
              center={value.stationCoordinates}
              pathOptions={value.status & isToday(new Date(value.createdAt)) ? greenOptions : redOptions}
              radius={10}
              key={index}
            >
              <Popup className="w-max" closeButton={false}>
                <MapStatusComponent stationObject={value} />
              </Popup>
            </CircleMarker>
          );
        })}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

const MapStatusComponent = ({ stationObject }) => {
  return (
    <div className="flex flex-wrap w-56 h-56 md:w-full md:h-full">
      <div
        className={
          stationObject.status & isToday(new Date(stationObject.createdAt))
            ? "w-full bg-green-500 px-2 py-1 rounded-lg mb-5 text-white text-lg font-bold"
            : "w-full bg-red-500 px-2 py-1 rounded-lg mb-5 text-white text-lg font-bold"
        }
      >
        <div className="text-center">
          {stationObject.status & isToday(new Date(stationObject.createdAt))
            ? `Deice is operational in ${stationObject.station}`
            : `Deice is not operational in ${stationObject.station}`}
        </div>
      </div>
      <div className="w-full h-28 md:h-full overflow-x-auto">
        <div className=" font-semibold grid grid-cols-4 justify-items-center gap-4 pb-2 mb-4 border border-t-0 border-r-0 border-l-0 border-b-2 border-black">
          <div className="col-span-2 place-self-start">Item</div>
          <div className="col-span-2 place-self-start">Status</div>
        </div>

        {Object.keys(stationObject).map((value, index) => {
          if (
            value === "_id" ||
            value === "station" ||
            value === "stationCoordinates" ||
            value === "__v" ||
            value === "status" ||
            value === "updatedAt"
          ) {
            return null;
          }
          return (
            <div
              className="w-full grid grid-cols-4 gap-4 pb-1 pt-1 items-center border border-gray-200 border-b-1 border-t-0 border-l-0 border-r-0 hover:bg-gray-200"
              key={index}
            >
              <div className="col-span-2 place-self-start self-center font-bold whitespace-nowrap">
                {value === "skyjackOperational"
                  ? "Skyjack is operational:"
                  : value === "skyjackAccessible"
                  ? "Skyjack is accessible:"
                  : value === "deiceTankHot"
                  ? "Deice tank hot :"
                  : value === "deicePumpChecked"
                  ? "Deice pump is checked:"
                  : value === "deiceTankLevel"
                  ? "Deice tank level:"
                  : value === "skyjackTankLevel"
                  ? "Skyjack tank level:"
                  : value === "comments"
                  ? "Comments:"
                  : value === "reportedBy"
                  ? "Reported by:"
                  : value === "createdAt"
                  ? "Reported at:"
                  : value}
              </div>

              <div
                className={
                  stationObject[value] === "Yes"
                    ? "px-2 py-1 rounded-lg text col-span-2 w-fit"
                    : stationObject[value] === "No" ||
                      !isToday(new Date(stationObject[value])) & (value === "createdAt")
                    ? "px-2 py-1 rounded-lg text-red-500 col-span-2 w-fit"
                    : "px-2 py-1 rounded-lg text col-span-2 "
                }
              >
                {stationObject[value] === "" ? "Not reported" : stationObject[value]}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
