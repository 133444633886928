import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
//import faker from "faker";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

//TODO change the scale so it shows 1,2,3,4 to the total number of stations.
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    tooltip: {
        callbacks: {
            footer: ()=>{console.log("tooltip callback")},
          }
    }
  },
};

export default function ChartLine({options,data}) {
  return <Line options={options} data={data} />;
}
